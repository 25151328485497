<template>
    <h1>What should be added to this website? Let us know!</h1>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScbSViiClh3_8na-CW9NDx99ksh3_4NaE_y9aviDZhZQMgCDg/viewform?embedded=true" width="640" height="585" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    <h1>Coming Soon...</h1>
    <img :src="image"/>
</template>


<script>
export default {
    name: "ComingSoon",
    data() {
        return {
            image: require("@/assets/kac-banner.png")
        }
    }
}
</script>

<style scoped>
img {
    max-width: 60%;
    height: auto;
    display: block;
    margin: 0 auto;
}

/* Adjust for smaller screens */
@media (max-width: 768px) {
    img {
        max-width: 80%;
    }
}

/* Adjust for very small screens */
@media (max-width: 480px) {
    img {
        max-width: 90%;
    }
}

</style>